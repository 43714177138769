if ('define' in window) {
define("discourse/theme-23/discourse/initializers/avatar", ["exports", "discourse/components/topic-list/header/posters-cell", "discourse/helpers/avatar", "discourse/lib/plugin-api", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _postersCell, _avatar, _pluginApi, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(23);
  const themePrefix = key => `theme_translations.23.${key}`;
  const ItemCell = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <td
      class="posters topic-list-data theme-avatar-{{settings.latest_avatar_size}}"
    >
      {{#each @topic.featuredUsers as |poster|}}
        {{#if poster.moreCount}}
          <a class="posters-more-count">{{poster.moreCount}}</a>
        {{else}}
          <a
            href={{poster.user.path}}
            data-user-card={{poster.user.username}}
            class={{poster.extraClasses}}
          >{{avatar
              poster
              avatarTemplatePath="user.avatar_template"
              usernamePath="user.username"
              namePath="user.name"
              imageSize=settings.latest_avatar_size
            }}</a>
        {{/if}}
      {{/each}}
    </td>
  
  */
  {
    "id": null,
    "block": "[[[1,\"\\n  \"],[10,\"td\"],[15,0,[29,[\"posters topic-list-data theme-avatar-\",[32,0,[\"latest_avatar_size\"]]]]],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"featuredUsers\"]]],null]],null],null,[[[41,[30,2,[\"moreCount\"]],[[[1,\"        \"],[10,3],[14,0,\"posters-more-count\"],[12],[1,[30,2,[\"moreCount\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,3],[15,6,[30,2,[\"user\",\"path\"]]],[15,\"data-user-card\",[30,2,[\"user\",\"username\"]]],[15,0,[30,2,[\"extraClasses\"]]],[12],[1,[28,[32,1],[[30,2]],[[\"avatarTemplatePath\",\"usernamePath\",\"namePath\",\"imageSize\"],[\"user.avatar_template\",\"user.username\",\"user.name\",[32,0,[\"latest_avatar_size\"]]]]]],[13],[1,\"\\n\"]],[]]]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[\"@topic\",\"poster\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "/discourse/theme-23/discourse/initializers/avatar",
    "scope": () => [settings, _avatar.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = {
    name: "discourse-avatar-component",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.34.0", api => {
        api.changeWidgetSetting("post-avatar", "size", settings.topic_avatars_size);
        api.registerValueTransformer("header-notifications-avatar-size", () => settings.header_avatars_size);
        api.registerValueTransformer("topic-list-columns", _ref => {
          let {
            value: columns
          } = _ref;
          columns.replace("posters", {
            header: _postersCell.default,
            item: ItemCell
          });
          return columns;
        });
      });
    }
  };
});
}
